<template>

  <b-card class="logo-card">

    <div class="bg-logo">
      <svg width="299" height="135" viewBox="0 0 299 135" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="32.2518" y="48.9014" width="22.7481" height="67.6054" fill="#26DE81"/>
        <line x1="42.8621" y1="32" x2="42.8621" y2="49.5597" stroke="#26DE81"/>
        <line x1="42.8621" y1="116.507" x2="42.8621" y2="134.067" stroke="#26DE81"/>
        <rect y="16.9014" width="22.7481" height="67.6054" fill="#FF231F"/>
        <line x1="10.6104" y1="8.64691e-09" x2="10.6103" y2="17.5597" stroke="#FF231F"/>
        <line x1="10.6104" y1="84.5068" x2="10.6104" y2="102.067" stroke="#FF231F"/>
      </svg>
    </div>

    <h1 class="text-primary welcome-text mt-5">Welcome to {{appName}}</h1>

  </b-card>
</template>

<script>
import { BRow, BCol , BCard } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import appName from "@/main";

export default {
  title:"Home",
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      appName,
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
}
</script>

<style lang="scss">
.logo-card{
  height: 80vh;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-items: center;
  //justify-content: center;
  align-items: center;
}
.bg-logo{
  margin-left: auto;
  margin-right: auto;
  width: 5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  //background-image: url("../../../../src/assets/images/logo/logo-jadid.png");
}
.welcome-text{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 900;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
